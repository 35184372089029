import * as React from "react"
import { Link } from "gatsby"

import LayoutContent from "../components/layout-content"
import Seo from "../components/seo"
import "./cv.css"

const AboutPage = () => (
  <LayoutContent>
    <Seo title="CV" />
    <div
      style={{
        backgroundColor: `white`,
        color: `black`,
      }}
    >
      <h1>Sean Mayhew CV</h1>
      <p>
        Infrastructure as Code and Automation Developer for large enterprise. Primary
        focus consists of automating application deployment and monitoring.
      </p>
      <p>Happily employed - not seeking new opportunities.</p>
      <h2>Experience</h2>
      <h3>Enterprise, Remote Worker</h3>
      <ol>
        <li>
          <strong>Principal IT Developer - Infrastructure</strong> 2019-Present
        </li>
        <li>
          <strong>
            Principal IT Developer - Analytics and Application Development
          </strong>{" "}
          2017-2019
        </li>
        <li>
          <strong>Sr. IT Developer</strong> 2014-2017
        </li>
        <li>
          <strong>
            Self Employed/Contractor - Analytics UI/UX Development
          </strong>{" "}
          2011-2014
        </li>
      </ol>
      <h4>Principal IT Developer - Infrastructure</h4>
      <p>
        Primary responsiblities consist in the management and monitoring of{" "}
        <strong>Elasticsearch</strong> clusters.
      </p>
      <p>
        Elasticsearch alone is a <strong>large</strong> topic with a lot of
        components and API. Below I am focusing on primary responsiblities
        though I have chosen to leave some things out.
      </p>
      <p>This consists of:</p>
      <ul>
        <li>
          Authoring and Manging <em>Infrastructure as Code</em> utilizing{" "}
          <strong>Ansible</strong> Playbooks
        </li>
        <li>Ansible Tower integration for automated deployments</li>
        <li>
          Establishing and ensuring best practices for keeping clusters and
          their related applications updated backed up and monitored.
        </li>
        <li>Alignment with Change Management</li>
        <li>Configuration management and cluster structure and sizing</li>
        <li>
          MultiTenant team collaboration to enable a platform that supports
          multiple areas of the organization.
        </li>
        <li>
          Cluster Architectures Include:
          <ul>
            <li>
              Various Cluster Configuration Types:
              <ul>
                <li>All Master/Data/Ingest</li>
                <li>Dedicated Master/Client Kibana</li>
                <li>Basic and Platinum Licenses</li>
              </ul>
            </li>
            <li>
              Logstash Pipelines with ingestion via Kafka for back-pressure
            </li>
            <li>
              Beats:
              <ul>
                <li>Filebeat for log ingestion to Monitoring Cluster</li>
                <li>Metricbeat for system and Elasticsearch Monitoring</li>
                <li>
                  Heartbeat for monitoring numerous applications and endpoints
                </li>
              </ul>
            </li>
            <li>Kibana moniotoring for Rules, Alerts and Watcher</li>
          </ul>
        </li>
      </ul>

      <h4>Principal IT Developer - Analytics and Application Development</h4>
      <p>
        Application developer with UI/UX Focus supporting legacy .NET Monolith
        and lead effort to transition to microservice based architecture.
      </p>
      <h2>Education</h2>
      <p>I did not finish college. I barely started. I am proud of this.</p>
      <p>
        I have little formal education outside of your standard American public
        school system. I spent roughly a year and a half in community
        college(s). Realizing I didn't really have the money to keep going and I
        was mostly teaching myself anyway - I decided to do just that. Got a low
        paying part-time paid internship doing 'graphic design' at a nonprofit called 
        Central Florida Cultural Endeavors. I made something like $140/week and a side 
        job working at Denny's to make ends meet. Saved the money. 
        Served a lot of Grand Slam Breakfasts. Became self sufficient.
      </p>
      <p>
        The internship offered such a broad range of materials, posters, shirts,
        brochures, billboards, website(s) it provided me a rather large
        portfolio of work primarily created with QuarkXPress and Macromedia
        tools. I was able to transition to working for the circulation
        department of a newspaper that gave me an actual yearly salary and a
        401k - sweet! I was really blessed to have both of these opportunities
        as the volume and variety of work was interesting, challenging and the
        portfolio continued to grow. The rest is 20 years following the natural
        flow of work and interests.
      </p>
      <h2>Contact</h2>
      <p>
        I follow the Cal Newport - <strong>Deep Work</strong> model of
        productivity and do not use <em>any</em> social media (zip, zero, rien,
        nada, keine) but if you're dying to reach me you can use my first and
        last name at gmail no guarantees I will read it or respond.
      </p>
    </div>
  </LayoutContent>
)

export default AboutPage
